<template>
	<s-crud
		title="Parámetros x Cultivo"
		:filter="filter"
		:config="config"
		@save="save($event)"
		add
		edit
		remove
		search-input
	>
		<template v-slot:filter>
			<v-container>
				<v-row style="margin-left: 5px" justify="center">
					<v-col cols="6" lg="3">
						<s-select-definition
							clearable
							v-model="filter.TypeCultive"
							:def="1173"
							label="Cultivo"
						></s-select-definition>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<template scope="props">
			<v-container>
				<v-row justify="center">
					<v-col cols="12" lg="3" md="3">
						<s-select-definition
							v-model="props.item.TypeCultive"
							:def="1173"
							label="Cultivo"
						></s-select-definition>
					</v-col>
					<v-col cols="12" lg="3" md="3">
						<s-select-definition
							v-model="props.item.TypeCharge"
							:def="1231"
							label="Tipo Carga"
						></s-select-definition>
					</v-col>
                    <v-col cols="12" lg="2" md="3">
                        <s-text label="Peso Prom. Min"  decimal v-model.number="props.item.WpcMin">
                        </s-text> 
                    </v-col>
                    <v-col cols="12" lg="2" md="3">
                         <s-text label="Peso Prom. Max" decimal v-model.number="props.item.WpcMax">
                        </s-text> 
                    </v-col>
				</v-row>
			</v-container>
		</template>

        <template v-slot:SecStatus="{row}">
            <v-chip
                x-small
                :color="row.SecStatus == 1 ? 'success' : 'error'"
            >
                {{row.SecStatus == 1 ? 'Activo' : 'Inactivo'}}
            </v-chip>
        </template>
	</s-crud>
</template>

<script>
	import _sWeighedParamConfig from "../../../services/FreshProduction/PrfWeighedParamConfigService.js";

	export default {
		components: { },
		data: () => ({
			selected: {},
			filter: {},
			config: {
				service: _sWeighedParamConfig,
				model: {
					WpcID: "ID",
                    SecStatus: "",
				},
				headers: [
					{
						text: "ID",
						value: "WpcID",
                        width: "10",
                        align:"center"
					},
					{
						text: "Cultivo",
						value: "TypeCultiveName",
                        width: "400"
					},
					{
						text: "Tipo Carga",
						value: "TypeChargeName",
                        width: "200"
					},
                    {
						text: "Peso Prom. Minimo",
						value: "WpcMin",
                        width: "200",
                        align:"center"
					},
                    {
						text: "Peso Prom. Maximo",
						value: "WpcMax",
                        width: "200",
                        align:"center"
					},
                    {
						text: "Estado",
						value: "SecStatus",
                        width: "200",
					},

				]
			}
		}),

		methods: {

			save(item) {
				console.log("Guardar", item);
                if(item.TypeCultive == null){
                    this.$fun.alert("Seleccione un Cultivo", "warning")
                    return
                }

				if(item.TypeCharge == null){
                    this.$fun.alert("Seleccione Tipo de Carga", "warning")
                    return
                }

                if(item.WpcMin <= 0){
                    this.$fun.alert("Ingrese valor para Peso Prom. (Jaba/Bines) Minimo", "warning")
                    return
                }

                if(item.WpcMax <= 0){
                    this.$fun.alert("Ingrese valor para Peso Prom. (Jaba/Bines) Maximo", "warning")
                    return
                }

                if(item.WpcMax < item.WpcMin){
                    this.$fun.alert("El valor maximo no puede ser menor a valor Minimo", "warning")
                    return
                }


				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();
				

				item.save();


			}
		}
	};
</script>
